import './App.scss';
import { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { API_URL }  from './components/config';
import SearchBox from './components/SearchBox';
import MovieList from './components/Movielist';
//import useFetch from './components/useHooks/useFetch';

function App() {
	const [movies, setMovies] = useState([]);
	const [page, setPage] = useState(1);
	const [loading, setLoading] = useState(false);
	const [count, setCount] = useState(0);
	const [searchTerm, setSearchTerm] = useState();

	const trackingId = "UA-221284336-1"; // Replace with your Google Analytics tracking ID
	ReactGA.initialize(trackingId);
	//ReactGA.pageview(`/`);

  	const fetchMovies = async (search = 0, page = 1) => {

		try {

			//let url = `${API_URL}/list_movies.json?page=${this.state.currentPage + 1}&limit=10`;
			let url = `${API_URL}/list_movies.json?page=${page}&query_term=${search}&limit=50&quality=2160p`;
			let res = await fetch(url);
			let data = await res.json();
			let results = data.data;
			let count = results.movie_count;
			// console.log(results);
			//return results.movies;
      		setMovies([...movies, ...results.movies]);
			setCount(count);
		}
		catch (err) {
			//console.log(err);
			setCount(0);
		}
	};

	
	const searchEvent = (term) => {
		setMovies([]);
		setLoading(true);
		setSearchTerm(term);
		setPage(1);
	};

	const loadMore = () => {
		setLoading(true);
		setPage(page + 1);
		
	}
	
	useEffect(() =>{
		const timeoutId = setTimeout(() => {
			fetchMovies(searchTerm, page);
			setLoading(false);
			ReactGA.event({
				category: 'Movie',
				action: 'search',
				label: `${searchTerm}`
			});
			}
			, 800);
			return () => clearTimeout(timeoutId);
	},[searchTerm, page]);

	return (
		<div className="App">
			<SearchBox searchChange={searchEvent} count = {count}/>
			<MovieList movies={movies} loading={loading} count={count} loadMore = {loadMore} />
		</div>
	);
}

export default App;
