import './style.scss';
//import { useState, useEffect } from 'react';


const Modal = ( { show, onClose, children } ) => {

	if (!show) {
		return null;
	}

	return (
		<div className="movie-modal-backdrop">
                <div className="movie-modal">
                    {children}
                    <button onClick={onClose}>
                        X
                    </button>
                </div>
        </div >
	)
}

export default Modal;