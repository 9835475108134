import './style.scss';
import { useState } from 'react';
import ReactGA from 'react-ga';
import MovieItem from '../MovieItem';
import MovieDetail from '../MovieDetail';
import Modal from '../Modal';


const MovieList = ( {movies, loading, count, loadMore} ) => {

	const [movieId, setId] = useState();
	const [youtube, setYoutube] = useState();
	const [isOpen, setOpen] = useState(false);
	const [isModalOpen, setModalOpen] = useState(false);

	//console.log("movielists: ", movies);

	const openMovieDetail = (id,title,slug) => {
		setId(id);
		// setYoutube(id);
		setOpen(true);
		ReactGA.pageview(`/movie/${slug}`);
		ReactGA.event({
			category: 'Movie',
			action: 'click',
			label: title
		});
		//console.log(id,title,slug);
	}
	const closeMovieWindow = () => {
		setOpen(false);
	}

	const closeModal = () => {
		setModalOpen(false);
	}

	const openModal = (youtube) => {
		setModalOpen(true);
		setYoutube(youtube);
    }

	const getYoutubeLink = (e) => {
		openModal(e);
	}
	//console.log("movielists:", movies);
	return (
		<>
			<div className="movie-list-outer">
			<div className={`movie-list ${isOpen ? 'open' : 'close'}`}>
			{(
				!!movies ? (
					movies.map((movie, i) => { 
						return <MovieItem 
							key={i}
							movieId={movie.id}
							slug={movie.slug}
							title={movie.title}
							cover={movie.medium_cover_image}
							youtube={movie.yt_trailer_code}
							genres={movie.genres}
							imdb={movie.imdb_code}
							openMovieWindow={openMovieDetail}
							sendYoutubeLink={getYoutubeLink}
						/>
					})
				) : null
			)}
			</div>
			<div style={{width: '100%', textAlign: 'center'}}>{loading ? <p>Loading...</p> : movies.length !== count ? <div id="load" onClick={loadMore}>Load more</div> : null}</div>
			</div>
			<Modal show={isModalOpen}
                onClose={closeModal}>
                <iframe width="100%" height="100%" title="yt embed code"
                    src={`https://www.youtube.com/embed/${youtube}?enablejsapi=1&autoplay=1`}
                	frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                	allowFullScreen />
            </Modal>
			<MovieDetail 
				show = {isOpen}
				movieId={movieId}
				closeWindow = {closeMovieWindow}
			/>
		</>
	)
}

export default MovieList;