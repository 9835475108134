import './style.scss';
import ReactGA from 'react-ga';

const MovieItem = ( {movieId,title,slug,cover,youtube,genres,imdb, openMovieWindow, sendYoutubeLink} ) => {

	const onOpenModal = (e) => {
		sendYoutubeLink(e);
		ReactGA.event({
			category: 'Button',
			action: 'click',
			label: `Trailer about ${title}`,
		})
	};

	const onClickImdb = () => {
		ReactGA.event({
			category: 'Button',
			action: 'click',
			label: `Imdb about ${title}`,
		})
	};

	return (
	<div className="movie-item">
		<img src={cover} alt={title} onClick={() => openMovieWindow(movieId,title,slug)} />
		<p>{title}</p>
		{genres ? <p className="genres">{genres.map((genr) => genr + ' ')}</p> : null }
		<div className="buttons">
		{youtube ?
            // <a href={`//www.youtube.com/embed/${this.props.youtube}`} target="_blank">Youtube trailer</a>
            <a onClick={() => onOpenModal(youtube)}>Trailer</a>
            : null}
    		<a onClick={() => onClickImdb()} className="imdb" href={`https://www.imdb.com/title/${imdb}`} target="_blank" rel="noopener noreferrer">IMDB</a>
		</div>
	</div>
	)
}
export default MovieItem;