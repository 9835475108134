import './style.scss';
import { API_URL }  from '../config';
import { useEffect, useState } from 'react';
import ReactGA from 'react-ga';

const MovieDetail= ( {show,movieId,closeWindow} ) => {
	const [movieInfo, setMovieInfo] = useState(
		{
			title: null,
			description: null,
			torrents: [],
			bgimage: null,
			image: null,
			year: null,
			rating: '',
			runtime: '',
			imdb: null
		}
	);

	const onClickTorrent = (type,quality,urltype) => {
		ReactGA.event({
			category: 'Button',
			action: `${urltype}`,
			label: ` ${movieInfo.title}: ${type}, ${quality}`,
		});
	};

	useEffect( () => {

		setMovieInfo([]);

		const fetchMovie = async () => {
			try {
				let url = `${API_URL}/movie_details.json?movie_id=${movieId}&with_images=0&with_cast=true`;
				let res = await fetch(url);
				let data = await res.json();
				let movie = data.data.movie
				//console.log(movie);
				setMovieInfo({
					title: movie.title,
					description: movie.description_full,
					torrents: movie.torrents,
					bgimage: movie.background_image_original,
					image: movie.large_cover_image,
					runtime: movie.runtime,
				});
			}
			catch (err) {
				
			}
			
		};

		fetchMovie();

	},[movieId]);

	let torrents = [];
	const isEmpty = Object.keys(movieInfo).length;
	
	if(movieInfo.torrents) {
		torrents = movieInfo.torrents.map((element, i) => {
			// console.log(element);
			const {type,size,quality,url,hash} = element;
			const torrent =  quality === '2160p' ? (
				<div key={i} className="movie-detail-torrent">
					<p>Type: {type}</p>
					<p>Size: {size}</p>
					<p>Quality: {quality}</p>
					<div className="buttons">
						<p><a onClick={() => onClickTorrent(type,quality,'download')} href={url}>download</a></p>
						<p><a onClick={() => onClickTorrent(type,quality,'magnet')} href={`magnet:?xt=urn:btih:${hash}&dn=${movieInfo.title}&tr=http://track.one:1234/announce&tr=udp://p4p.arenabg.ch:1337`} >magnet</a></p>
					</div>
				</div>
				)
				: null;
			return torrent;
		});
	}

	return (
		
		!!show ? (
		<div className="movie-detail">
			{ 
			
			//console.log(isEmpty)
			//console.log((movieInfo))
			isEmpty > 0 ? 
			( 
			<>
				
				<div className="movie-detail-media">
					<div className="movie-detail-image">
						<img src={movieInfo.image} alt={movieInfo.title} />
					</div>
					<div className="movie-detail-content">
						<h1>{movieInfo.title}</h1>
						<p>{movieInfo.description}</p>
						<div className="movie-detail-torrents-info">{torrents}</div>
					</div>
				</div>
				<div className="close" onClick={closeWindow}>
					<div className="close-inner" />
				</div>
			</>
			)
			: 
			<div className="loading"><h1>loading... </h1></div>
			}
			
		</div>
		)
		: null
		
	)
}
export default MovieDetail;