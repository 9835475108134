import './style.scss';
// import { useState } from 'react';


const SearchBox = ( { searchChange, count} ) => {
	// const [isChanged, setChanged] = useState(false);
	return (
		<div className="search">

			<label htmlFor="search"><h1>4K movies</h1> 
				<input type="search" 
					placeholder="type movie name..." 
					id="search"
					onChange={(e) => {
						searchChange(e.target.value);
						// setChanged(true);
						//console.log(e);
					}}
				/>
			</label>
			<p>We have {count} movie{count === 1 ? null : "s"}.</p>
		</div>
	)
}

export default SearchBox;